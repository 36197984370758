import _tslib from "tslib";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib = _tslib;

var clamp = function (min, max) {
  return function (v) {
    return Math.max(Math.min(v, max), min);
  };
};

var sanitize = function (v) {
  return v % 1 ? Number(v.toFixed(5)) : v;
};

var floatRegex = /(-)?(\d[\d\.]*)/g;
var colorRegex = /(#[0-9a-f]{6}|#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?[\d\.]+%?[,\s]+){2,3}\s*\/*\s*[\d\.]+%?\))/gi;
var singleColorRegex = /^(#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?[\d\.]+%?[,\s]+){2,3}\s*\/*\s*[\d\.]+%?\))$/i;
var number = {
  test: function (v) {
    return typeof v === "number";
  },
  parse: parseFloat,
  transform: function (v) {
    return v;
  }
};

var alpha = tslib.__assign(tslib.__assign({}, number), {
  transform: clamp(0, 1)
});

var scale = tslib.__assign(tslib.__assign({}, number), {
  default: 1
});

var createUnitType = function (unit) {
  return {
    test: function (v) {
      return typeof v === "string" && v.endsWith(unit) && v.split(" ").length === 1;
    },
    parse: parseFloat,
    transform: function (v) {
      return "" + v + unit;
    }
  };
};

var degrees = createUnitType("deg");
var percent = createUnitType("%");
var px = createUnitType("px");
var vh = createUnitType("vh");
var vw = createUnitType("vw");

var progressPercentage = tslib.__assign(tslib.__assign({}, percent), {
  parse: function (v) {
    return percent.parse(v) / 100;
  },
  transform: function (v) {
    return percent.transform(v * 100);
  }
});

var getValueFromFunctionString = function (value) {
  return value.substring(value.indexOf("(") + 1, value.lastIndexOf(")"));
};

var clampRgbUnit = clamp(0, 255);

var isRgba = function (v) {
  return v.red !== undefined;
};

var isHsla = function (v) {
  return v.hue !== undefined;
};

function getValuesAsArray(value) {
  return getValueFromFunctionString(value).replace(/(,|\/)/g, " ").split(/ \s*/);
}

var splitColorValues = function (terms) {
  return function (v) {
    if (typeof v !== "string") return v;
    var values = {};
    var valuesArray = getValuesAsArray(v);

    for (var i = 0; i < 4; i++) {
      values[terms[i]] = valuesArray[i] !== undefined ? parseFloat(valuesArray[i]) : 1;
    }

    return values;
  };
};

var rgbaTemplate = function (_a) {
  var red = _a.red,
      green = _a.green,
      blue = _a.blue,
      _b = _a.alpha,
      alpha = _b === void 0 ? 1 : _b;
  return "rgba(" + red + ", " + green + ", " + blue + ", " + alpha + ")";
};

var hslaTemplate = function (_a) {
  var hue = _a.hue,
      saturation = _a.saturation,
      lightness = _a.lightness,
      _b = _a.alpha,
      alpha = _b === void 0 ? 1 : _b;
  return "hsla(" + hue + ", " + saturation + ", " + lightness + ", " + alpha + ")";
};

var rgbUnit = tslib.__assign(tslib.__assign({}, number), {
  transform: function (v) {
    return Math.round(clampRgbUnit(v));
  }
});

function isColorString(color, colorType) {
  return color.startsWith(colorType) && singleColorRegex.test(color);
}

var rgba = {
  test: function (v) {
    return typeof v === "string" ? isColorString(v, "rgb") : isRgba(v);
  },
  parse: splitColorValues(["red", "green", "blue", "alpha"]),
  transform: function (_a) {
    var red = _a.red,
        green = _a.green,
        blue = _a.blue,
        _b = _a.alpha,
        alpha$1 = _b === void 0 ? 1 : _b;
    return rgbaTemplate({
      red: rgbUnit.transform(red),
      green: rgbUnit.transform(green),
      blue: rgbUnit.transform(blue),
      alpha: sanitize(alpha.transform(alpha$1))
    });
  }
};
var hsla = {
  test: function (v) {
    return typeof v === "string" ? isColorString(v, "hsl") : isHsla(v);
  },
  parse: splitColorValues(["hue", "saturation", "lightness", "alpha"]),
  transform: function (_a) {
    var hue = _a.hue,
        saturation = _a.saturation,
        lightness = _a.lightness,
        _b = _a.alpha,
        alpha$1 = _b === void 0 ? 1 : _b;
    return hslaTemplate({
      hue: Math.round(hue),
      saturation: percent.transform(sanitize(saturation)),
      lightness: percent.transform(sanitize(lightness)),
      alpha: sanitize(alpha.transform(alpha$1))
    });
  }
};

var hex = tslib.__assign(tslib.__assign({}, rgba), {
  test: function (v) {
    return typeof v === "string" && isColorString(v, "#");
  },
  parse: function (v) {
    var r = "";
    var g = "";
    var b = "";

    if (v.length > 4) {
      r = v.substr(1, 2);
      g = v.substr(3, 2);
      b = v.substr(5, 2);
    } else {
      r = v.substr(1, 1);
      g = v.substr(2, 1);
      b = v.substr(3, 1);
      r += r;
      g += g;
      b += b;
    }

    return {
      red: parseInt(r, 16),
      green: parseInt(g, 16),
      blue: parseInt(b, 16),
      alpha: 1
    };
  }
});

var color = {
  test: function (v) {
    return typeof v === "string" && singleColorRegex.test(v) || isRgba(v) || isHsla(v);
  },
  parse: function (v) {
    if (rgba.test(v)) {
      return rgba.parse(v);
    } else if (hsla.test(v)) {
      return hsla.parse(v);
    } else if (hex.test(v)) {
      return hex.parse(v);
    }

    return v;
  },
  transform: function (v) {
    if (isRgba(v)) {
      return rgba.transform(v);
    } else if (isHsla(v)) {
      return hsla.transform(v);
    }

    return v;
  }
};
var COLOR_TOKEN = "${c}";
var NUMBER_TOKEN = "${n}";

var convertNumbersToZero = function (v) {
  return typeof v === "number" ? 0 : v;
};

var complex = {
  test: function (v) {
    if (typeof v !== "string" || !isNaN(v)) return false;
    var numValues = 0;
    var foundNumbers = v.match(floatRegex);
    var foundColors = v.match(colorRegex);
    if (foundNumbers) numValues += foundNumbers.length;
    if (foundColors) numValues += foundColors.length;
    return numValues > 0;
  },
  parse: function (v) {
    var input = v;
    var parsed = [];
    var foundColors = input.match(colorRegex);

    if (foundColors) {
      input = input.replace(colorRegex, COLOR_TOKEN);
      parsed.push.apply(parsed, foundColors.map(color.parse));
    }

    var foundNumbers = input.match(floatRegex);

    if (foundNumbers) {
      parsed.push.apply(parsed, foundNumbers.map(number.parse));
    }

    return parsed;
  },
  createTransformer: function (prop) {
    var template = prop;
    var token = 0;
    var foundColors = prop.match(colorRegex);
    var numColors = foundColors ? foundColors.length : 0;

    if (foundColors) {
      for (var i = 0; i < numColors; i++) {
        template = template.replace(foundColors[i], COLOR_TOKEN);
        token++;
      }
    }

    var foundNumbers = template.match(floatRegex);
    var numNumbers = foundNumbers ? foundNumbers.length : 0;

    if (foundNumbers) {
      for (var i = 0; i < numNumbers; i++) {
        template = template.replace(foundNumbers[i], NUMBER_TOKEN);
        token++;
      }
    }

    return function (v) {
      var output = template;

      for (var i = 0; i < token; i++) {
        output = output.replace(i < numColors ? COLOR_TOKEN : NUMBER_TOKEN, i < numColors ? color.transform(v[i]) : sanitize(v[i]));
      }

      return output;
    };
  },
  getAnimatableNone: function (target) {
    var parsedTarget = complex.parse(target);
    var targetTransformer = complex.createTransformer(target);
    return targetTransformer(parsedTarget.map(convertNumbersToZero));
  }
};
exports.alpha = alpha;
exports.color = color;
exports.complex = complex;
exports.degrees = degrees;
exports.hex = hex;
exports.hsla = hsla;
exports.number = number;
exports.percent = percent;
exports.progressPercentage = progressPercentage;
exports.px = px;
exports.rgbUnit = rgbUnit;
exports.rgba = rgba;
exports.scale = scale;
exports.vh = vh;
exports.vw = vw;
export default exports;
export const __esModule = exports.__esModule;
const _alpha = exports.alpha,
      _color = exports.color,
      _complex = exports.complex,
      _degrees = exports.degrees,
      _hex = exports.hex,
      _hsla = exports.hsla,
      _number = exports.number,
      _percent = exports.percent,
      _progressPercentage = exports.progressPercentage,
      _px = exports.px,
      _rgbUnit = exports.rgbUnit,
      _rgba = exports.rgba,
      _scale = exports.scale,
      _vh = exports.vh,
      _vw = exports.vw;
export { _alpha as alpha, _color as color, _complex as complex, _degrees as degrees, _hex as hex, _hsla as hsla, _number as number, _percent as percent, _progressPercentage as progressPercentage, _px as px, _rgbUnit as rgbUnit, _rgba as rgba, _scale as scale, _vh as vh, _vw as vw };